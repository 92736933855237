@import '../mixin';
$default-text:14px;
$sm-text:12px;
$lg-text:16px;
$body-bg:#fff;
$sidebar-bg:#465294;
$sidebar-fonts-color:#fff;
$card-bg:#fff;
$card-border:#e4e3e3;
$white:#fff;
$black:#000;
$select-color:rgba($black, .1);
$border-color:rgba($black, .1);
$table-border-color:#e6e6e6;
$fonts-color:#428bca;
$primary:#428bca;
$primary-second:#428bca;
$primary-third:#03a9f4;
$primary-fourth:#00bcd4;
$secondory:#ffffff;
$secondory-text:#02549a;
$success:#16be9a;
$danger:#428bca;
$warning:#ff7403;
$info:#a3bde1;
$title:#a3bde1;
$primary-mybackpathways: #34495e;
$primary-new:white;
$primary-second-new: #f4f4f4;
$topNavFontColor:#A7AEB1;



body {
    background: $body-bg;
}

.bg-default {
    @include linear-gradient(to top right, darken($card-bg, 40%), darken($card-bg, 30%));
}

.bg-primary {
    @include linear-gradient(to top right, $primary, #ffd17c);
}

.bg-success {
    @include linear-gradient(to top right, $success, #42e697);
}

.bg-danger {
    @include linear-gradient(to top right, $danger, #fda09c);
}

.bg-warning {
    @include linear-gradient(to top right, $warning, #fac980);
}

.bg-info {
    @include linear-gradient(to top right, $info, #15f8bf);
}

button.mat-default {
    @include linear-gradient(to top right, darken($card-bg, 40%), darken($card-bg, 30%));
}

button.mat-primary {
    @include linear-gradient(to top right, $primary, $primary-second);
}

button.mat-success {
    @include linear-gradient(to top right, $success, #42e697);
}

button.mat-danger {
    @include linear-gradient(to top right, $danger, $danger);
    color: white;
}

button.mat-warning {
    @include linear-gradient(to top right, $warning, #fca063);
}

button.mat-info {
    @include linear-gradient(to top right, $info, #15f8bf);
}
