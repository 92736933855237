// @media screen and (max-width: 992px) {
//     .main-container {
//         left: 0px;
//     }
// }
// @media screen and (min-width: 992px) {
//     .push-right {
//       .main-container {
//           left: 0px;
//       }
//     }
// }

@media screen and (max-width: 992px) {
    .push-right {
        #sidebar {
            left: 0px !important;
        }
    }
}
@media screen and (min-width: 992px) {
    .push-right {
        #sidebar {
            left: 0px !important;
        }
    }
}
