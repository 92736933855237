@use '@angular/material' as mat;
@font-face {
    font-family: 'robotoLight';
    src: url('../assets/fonts/FontinSans/roboto.light.ttf') format('opentype');
}

@import '@angular/material/prebuilt-themes/indigo-pink.css';
@import "~@ng-select/ng-select/themes/material.theme.css";
@import url('https://fonts.googleapis.com/css2?family=Mada:wght@200;300;400;500;600;700;900&display=swap');
// $font-family: 'Fontil Sans, monospace',
$custom-typography: mat.define-typography-config(
    $font-family: 'Arial',
    $headline: mat.define-typography-level(32px, 48px, 700),
    $body-1: mat.define-typography-level(16px, 24px, 500)
);
@include mat.all-component-typographies($custom-typography);

.mat-toolbar.mat-primary {
    background: $primary-new;
    color: black;
}

.mat-toolbar.mat-primary-mybackpathways{
    background: $primary-new;
    color: black;

}


