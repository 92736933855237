html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Arial';
}

.main-container {
  position: absolute;
  top: 105px;
  left: 120px;
  right: 0;
  bottom: 0;
  padding: 10px 10px 10px 10px;
  width: calc(100% - 140px);
}


.defaultColor {
  color: $primary;
}

.greenColor {
  color: green;
}

.mb-20 {
  margin-bottom: 20px;
}

.open-container>* {
  width: 100%;
}

.footer {
  position: absolute;
  font-size: 1em;
  right: 10px;
  bottom: 10px;
  color: $fonts-color;

  a {
    color: $secondory-text;
    text-decoration: none;
  }

  a:hover {
    color: $secondory-text;
    text-decoration: underline;
  }
}

.myPreviewClass .mat-dialog-container {
  background-color: transparent;
  box-shadow: none;
  overflow: hidden;
}

.iframeclass .mat-dialog-container {
  /* add your styles */
  padding: 0px !important;
}

.cell-container-main {
  width: 100% !important;
  min-height: 630px !important;
}

.cell-container-day-main {
  width: 100% !important;
  min-height: 630px !important;
}